function NotFound () {

    return (
        <section className="NotFound">
            <div>
                <span className="Four">4</span>
                <span className="Zero">0</span>
                <span className="Four">4</span>
                <h2>The upgrade you... uhh... I mean the page you're looking for is not here. Maybe it's somewhere else?</h2>
            </div>
        </section>
    )
}

export default NotFound;